"use client";

import { SignalType } from "@/lib/signals";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import Link, { LinkProps } from "next/link";
import React, { ElementType, useEffect, useRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { twMerge } from "tailwind-merge";
import { Markdown } from "./markdown";
import { captureException } from "@sentry/nextjs";
export function Button({
  className,
  children,
  onClick,
  disabled,
  disabledReason,
  title,
  secondary,
  Icon,
  iconClass,
  confirm,
  dangerous,
  ...props
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>;
  disabledReason?: string;
  secondary?: boolean;
  Icon?: ElementType<{
    className?: string | undefined;
  }>;
  iconClass?: string;
  confirm?: string;
  dangerous?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const cls = twMerge("rounded-md border bg-blue-400 hover:bg-blue-700 px-2", disabled ? "opacity-50 cursor-not-allowed" : "", secondary ? "bg-gray-300 text-gray-700 border-gray-400 hover:bg-gray-400" : "", dangerous ? "bg-red-600 hover:bg-red-700 text-white" : "", className);
  const [error, setError] = useState<Error | null>(null);
  const [running, setRunning] = useState(false);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (disabled) {
      return;
    }
    if (confirm) {
      if (!window.confirm(confirm)) {
        return;
      }
    }
    let resp: any;
    try {
      resp = onClick(event);
      setError(null);
    } catch (e) {
      captureException(e);
      setError(e as Error);
      console.error("Error in handler:", e);
    }
    if (resp && resp.then) {
      setRunning(true);
      resp.catch((e: Error) => {
        captureException(e);
        setError(e);
      }).finally(() => {
        setRunning(false);
      });
    }
  }
  return <button onClick={handleClick} className={cls} disabled={disabled} title={disabled ? disabledReason || title : title} {...props} data-sentry-component="Button" data-sentry-source-file="input.tsx">
      {children}
      {Icon && <Icon className={twMerge("h-4 w-4 inline-block", iconClass)} />}
      {running && <span className="ml-2">...</span>}
      {error && <div className="text-red-600 text-xs">{error.message}</div>}
    </button>;
}
export function CheckButton({
  className,
  signal,
  on,
  off,
  onClass,
  offClass
}: {
  className?: string;
  signal: SignalType<boolean>;
  on: React.ReactNode;
  off: React.ReactNode;
  onClass?: string;
  offClass?: string;
}) {
  useSignals();
  onClass = onClass || "bg-green-600 border border-green-700 p-1";
  offClass = offClass || "bg-green-950 border border-green-700 p-1";
  const cls = twMerge(className, signal.value ? onClass : offClass);
  return <Button className={cls} onClick={() => {
    signal.value = !signal.value;
  }} data-sentry-element="Button" data-sentry-component="CheckButton" data-sentry-source-file="input.tsx">
      {signal.value ? on : off}
    </Button>;
}
type AProps = {
  blank?: boolean;
  className?: string;
  children: React.ReactNode;
} & LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;
export function A({
  blank,
  className,
  ...props
}: AProps) {
  const p = {
    ...props
  };
  const cls = twMerge("text-cyan-600 hover:underline", className);
  if (blank) {
    return <a {...p} className={cls} target="_blank" rel="noopener noreferrer"></a>;
  }
  return <Link className={cls} {...p} data-sentry-element="Link" data-sentry-component="A" data-sentry-source-file="input.tsx" />;
}
export function ButtonLink({
  blank,
  className,
  ...props
}: AProps) {
  return <A {...props} className={twMerge("rounded-md border bg-blue-400 hover:bg-blue-700 px-2 py-1",
  // Reset some of A's styles:
  "text-black hover:no-underline", className)} blank={blank} data-sentry-element="A" data-sentry-component="ButtonLink" data-sentry-source-file="input.tsx" />;
}
export function TextInput({
  signal,
  onEscape,
  onEnter,
  className,
  ...props
}: {
  signal: SignalType<string>;
  onEscape?: () => void;
  onEnter?: () => void;
  className?: string;
} & React.InputHTMLAttributes<HTMLInputElement>) {
  return <input spellCheck="true" className={twMerge("border border-gray-400 p-1 w-full", className)} value={signal.value} onInput={e => {
    signal.value = (e.target as HTMLInputElement).value;
  }} onKeyDown={e => {
    if (onEscape) {
      if (e.key === "Escape") {
        e.preventDefault();
        e.stopPropagation();
        onEscape();
      }
    }
    if (onEnter) {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        onEnter();
      }
    }
  }} {...props} data-sentry-component="TextInput" data-sentry-source-file="input.tsx" />;
}
export function ExpandableTextArea({
  signal,
  onEscape,
  onEnter,
  onShiftEnter,
  className,
  ...props
}: {
  signal: SignalType<string>;
  onEscape?: () => void;
  onEnter?: () => void;
  onShiftEnter?: () => void;
} & React.ComponentProps<typeof TextareaAutosize>) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    }
  }, []);
  return <TextareaAutosize ref={textareaRef} spellCheck={props.spellCheck === undefined ? true : props.spellCheck} enterKeyHint={props.enterKeyHint === undefined && onEnter ? "send" : props.enterKeyHint} className={twMerge("border border-gray-400 p-1 w-full resize-none", className)} value={signal.value} onInput={e => {
    signal.value = (e.target as HTMLTextAreaElement).value;
    if (props.onInput) {
      props.onInput(e);
    }
  }} onKeyDown={e => {
    if (e.key === "Escape" && onEscape) {
      e.preventDefault();
      e.stopPropagation();
      onEscape();
      return;
    }
    if (e.key === "Enter") {
      if (e.shiftKey && !e.altKey && !e.ctrlKey && onShiftEnter) {
        e.preventDefault();
        e.stopPropagation();
        onShiftEnter();
        return;
      } else if (!e.shiftKey && !e.altKey && !e.ctrlKey && onEnter) {
        e.preventDefault();
        e.stopPropagation();
        onEnter();
        return;
      }
    }
    if (props.onKeyDown) {
      props.onKeyDown(e);
    }
  }} {...props} data-sentry-element="TextareaAutosize" data-sentry-component="ExpandableTextArea" data-sentry-source-file="input.tsx" />;
}
export function EditableText({
  signal,
  emptyPlaceholder,
  placeholder,
  className,
  textarea,
  markdown,
  children
}: {
  signal: SignalType<string>;
  emptyPlaceholder?: string;
  placeholder?: string;
  className?: string;
  textarea?: boolean;
  markdown?: boolean;
  children?: React.ReactNode;
}) {
  useSignals();
  const [editing, setEditing] = useState(false);
  const [halfEditing, setHalfEditing] = useState(false);
  const editingValueSignal = useSignal("");
  function onEnter() {
    signal.value = editingValueSignal.value;
    setHalfEditing(false);
    setEditing(false);
  }
  function onEscape() {
    setHalfEditing(false);
    setEditing(false);
  }
  if (editing && textarea) {
    return <ExpandableTextArea signal={editingValueSignal} onEnter={onEnter} onEscape={onEscape} autoFocus placeholder={placeholder} />;
  }
  if (editing) {
    return <TextInput signal={editingValueSignal} onEnter={onEnter} onEscape={onEscape} autoFocus placeholder={placeholder} />;
  }
  const text = signal.value || emptyPlaceholder || "(empty)";
  let innerContent: React.ReactNode;
  if (markdown) {
    innerContent = <Markdown className={twMerge("inline-block", signal.value ? "" : "opacity-50", halfEditing ? "bg-yellow-100" : "")}>
        {text}
      </Markdown>;
  } else {
    innerContent = <span className={halfEditing ? "bg-yellow-100" : ""}>
        {children || text}
      </span>;
  }
  return <span className={twMerge("cursor-pointer", className)} onClick={event => {
    if (event.detail > 1) {
      editingValueSignal.value = signal.value;
      setHalfEditing(false);
      setEditing(true);
    } else {
      setHalfEditing(!halfEditing);
      setTimeout(() => {
        setHalfEditing(false);
      }, 1000);
    }
  }} data-sentry-component="EditableText" data-sentry-source-file="input.tsx">
      {innerContent}
    </span>;
}
export function Checkbox({
  signal,
  className
}: {
  signal: SignalType<boolean>;
  className?: string;
}) {
  useSignals();
  return <input type="checkbox" className={className} checked={signal.value} onChange={event => {
    signal.value = event.target.checked;
  }} data-sentry-component="Checkbox" data-sentry-source-file="input.tsx" />;
}
export function Label({
  title,
  children,
  className
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) {
  return <label className={twMerge("block", className)} data-sentry-component="Label" data-sentry-source-file="input.tsx">
      <div className="text-sm font-semibold">{title}</div>
      {children}
    </label>;
}
export type SelectOptionType<T> = {
  label: string;
  value: T;
  group?: string;
  key?: string;
};
export function Select<T>({
  signal,
  emptyOption,
  allowEmpty,
  options,
  label,
  className
}: {
  signal: SignalType<T>;
  emptyOption?: string;
  allowEmpty?: boolean;
  options: SelectOptionType<T>[];
  label?: React.ReactNode;
  className?: string;
}) {
  useSignals();
  if (label) {
    return <Label title={label}>
        <Select signal={signal} emptyOption={emptyOption} allowEmpty={allowEmpty} options={options} className={className} />
      </Label>;
  }
  options = options.map((o, i) => ({
    ...o,
    key: o.key || `item-${i}`
  }));
  const grouped: {
    group: string;
    options: SelectOptionType<T>[];
  }[] = [];
  for (const option of options) {
    const groupName = option.group || "";
    const group = grouped.find(g => g.group === groupName);
    if (group) {
      group.options.push(option);
    } else {
      grouped.push({
        group: groupName,
        options: [option]
      });
    }
  }
  const selectedOption = options.find(o => o.value === signal.value);
  return <select className={twMerge("bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5", className)} value={selectedOption?.key || ""} onChange={event => {
    const value = event.target.value;
    if (!value) {
      (signal as any).value = null;
    } else {
      const v = options.find(o => o.key === value);
      if (v) {
        signal.value = v.value;
      } else {
        console.warn("Invalid option value:", value);
        (signal as any).value = null;
      }
    }
  }} data-sentry-component="Select" data-sentry-source-file="input.tsx">
      {(allowEmpty || !selectedOption) && <option value="">{emptyOption}</option>}
      {grouped.map(group => {
      if (group.group) {
        return <optgroup key={group.group} label={group.group}>
              {group.options.map(option => {
            return <option key={option.key} value={option.key}>
                    {option.label}
                  </option>;
          })}
            </optgroup>;
      } else {
        return <React.Fragment key="no-group">
              {group.options.map(option => {
            return <option key={option.key} value={option.key}>
                    {option.label}
                  </option>;
          })}
            </React.Fragment>;
      }
    })}
    </select>;
}
export type InlineSelectOptionType<T> = Omit<SelectOptionType<T>, "label"> & {
  label: React.ReactNode;
};
export function InlineSelect<T>({
  signal,
  emptyOption,
  allowEmpty,
  options,
  className
}: {
  signal: SignalType<T>;
  emptyOption?: string;
  allowEmpty?: boolean;
  options: InlineSelectOptionType<T>[];
  className?: string;
}) {
  useSignals();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Handle click outside to close dropdown
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  options = options.map((o, i) => ({
    ...o,
    key: o.key || `item-${i}`
  }));
  const selectedOption = options.find(o => o.value === signal.value);

  // Group options
  const grouped: {
    group: string;
    options: InlineSelectOptionType<T>[];
  }[] = [];
  for (const option of options) {
    const groupName = option.group || "";
    const group = grouped.find(g => g.group === groupName);
    if (group) {
      group.options.push(option);
    } else {
      grouped.push({
        group: groupName,
        options: [option]
      });
    }
  }
  return <div className="relative" ref={dropdownRef} data-sentry-component="InlineSelect" data-sentry-source-file="input.tsx">
      <div onClick={() => setIsOpen(!isOpen)} className={twMerge("bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 cursor-pointer", "hover:border-gray-400 flex justify-between items-center", className)}>
        <span>{selectedOption?.label || emptyOption || "Select..."}</span>
        <span className="ml-2">▼</span>
      </div>

      {isOpen && <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto">
          {(allowEmpty || !selectedOption) && <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer" onClick={() => {
        (signal as any).value = null;
        setIsOpen(false);
      }}>
              {emptyOption || "None"}
            </div>}

          {grouped.map(group => <div key={group.group}>
              {group.group && <div className="px-4 py-2 font-semibold bg-gray-50 text-sm text-gray-700">
                  {group.group}
                </div>}
              {group.options.map(option => <div key={option.key} className={twMerge("px-4 py-2 cursor-pointer hover:bg-gray-100", signal.value === option.value ? "bg-blue-50" : "")} onClick={() => {
          signal.value = option.value;
          setIsOpen(false);
        }}>
                  {option.label}
                </div>)}
            </div>)}
        </div>}
    </div>;
}