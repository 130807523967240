import chatdb from "@/lib/client/chatdb";
import { SignalView } from "@/lib/signals";
import { DEFAULT_VOICE, VOICE_DESCRIPTIONS, VOICES, voiceSampleUrl, type VoiceType } from "@/lib/tts";
import { DEFAULT_ASSISTANT_NAME, DEFAULT_MODEL_FAMILY, DEFAULT_RESPONSE_STYLE, DEFAULT_TTS_MODEL, MODEL_FAMILIES, MODEL_FAMILY_DESCRIPTIONS, RESPONSE_STYLE_DESCRIPTIONS, RESPONSE_STYLES, TTS_MODEL_DESCRIPTIONS, TTS_MODELS, type LocationType, type ModelFamilyType, type ResponseStyleType, type TtsModelType, type UserSettingsType } from "@/lib/types";
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/nextjs";
import { Cog6ToothIcon, QuestionMarkCircleIcon } from "@heroicons/react/16/solid";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { useEffect } from "react";
import { twMerge } from "tailwind-merge";
import { debugModeSignal } from "./apppage";
import { InstallPrompt, PushNotificationPrompt } from "./appsubscriptions";
import { FieldSet, H1, PastDateView } from "./display";
import { MyLocationIcon } from "./icons";
import { Button, Label, Select, TextInput } from "./input";
export default function AuthMenu({
  className
}: {
  className?: string;
}) {
  return <div id="auth-menu" className={twMerge("inline-block", className)} data-sentry-component="AuthMenu" data-sentry-source-file="authmenu.tsx">
      <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="authmenu.tsx">
        <SignInButton data-sentry-element="SignInButton" data-sentry-source-file="authmenu.tsx">
          <span className="bg-blue-600 text-white py-1 sm:py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors mr-4 sm:px-8 text-sm sm:text-base cursor-default">
            Sign In/Up
          </span>
        </SignInButton>
      </SignedOut>
      <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="authmenu.tsx">
        <UserButton data-sentry-element="UserButton" data-sentry-source-file="authmenu.tsx">
          <UserButton.MenuItems data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx">
            <UserButton.Action label="App Settings" labelIcon={<Cog6ToothIcon className="h-4 w-4" />} open="settings" data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx">
              Settings
            </UserButton.Action>
            <UserButton.Link label="Terms of Service" labelIcon={<QuestionMarkCircleIcon className="h-4 w-4" />} href="/tos" data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx" />
          </UserButton.MenuItems>
          <UserButton.UserProfilePage label="App Settings" labelIcon={<QuestionMarkCircleIcon className="h-4 w-4" />} url="settings" data-sentry-element="unknown" data-sentry-source-file="authmenu.tsx">
            <SettingsPage data-sentry-element="SettingsPage" data-sentry-source-file="authmenu.tsx" />
          </UserButton.UserProfilePage>
        </UserButton>
      </SignedIn>
    </div>;
}
function SettingsPage() {
  useSignals();
  const settingsSignal = useSignal<UserSettingsType | undefined>(undefined);
  const locationStatusSignal = useSignal<string>("");
  const lastLocationSignal = useSignal<LocationType | undefined>(undefined);
  const voiceSignal = new SignalView<VoiceType>(settingsSignal, "voice", DEFAULT_VOICE, async newValue => {
    const audioUrl = voiceSampleUrl(newValue);
    const audio = new Audio(audioUrl);
    audio.play();
    await chatdb.updateUserSettings({
      ...settingsSignal.value,
      voice: newValue
    });
  });
  const ttsModelSignal = new SignalView<TtsModelType>(settingsSignal, "ttsModel", DEFAULT_TTS_MODEL, async newValue => {
    await chatdb.updateUserSettings({
      ...settingsSignal.value,
      ttsModel: newValue
    });
  });
  const modelSignal = new SignalView<ModelFamilyType>(settingsSignal, "modelFamily", DEFAULT_MODEL_FAMILY, async newValue => {
    await chatdb.updateUserSettings({
      ...settingsSignal.value,
      modelFamily: newValue
    });
  });
  const assistantNameSignal = new SignalView<string>(settingsSignal, "assistantName", "", async newValue => {
    if (!newValue) {
      const settings = {
        ...settingsSignal.value
      };
      delete settings.assistantName;
      await chatdb.updateUserSettings(settings);
    } else {
      await chatdb.updateUserSettings({
        ...settingsSignal.value,
        assistantName: newValue
      });
    }
  });
  const responseStyleSignal = new SignalView<ResponseStyleType>(settingsSignal, "responseStyle", DEFAULT_RESPONSE_STYLE, async newValue => {
    await chatdb.updateUserSettings({
      ...settingsSignal.value,
      responseStyle: newValue
    });
  });
  useEffect(() => {
    // Load both settings and last location
    Promise.all([chatdb.getUserSettings(), chatdb.getLatestLocation()]).then(([settings, location]) => {
      settingsSignal.value = settings;
      lastLocationSignal.value = location;
    });
  }, [settingsSignal, lastLocationSignal]);
  const options = VOICES.map(voice => ({
    label: `${voice} (${VOICE_DESCRIPTIONS[voice]})`,
    value: voice
  }));
  const ttsModelOptions = TTS_MODELS.map(model => ({
    label: TTS_MODEL_DESCRIPTIONS[model],
    value: model
  }));
  const modelOptions = MODEL_FAMILIES.map(model => ({
    label: MODEL_FAMILY_DESCRIPTIONS[model],
    value: model
  }));
  const responseStyleOptions = RESPONSE_STYLES.filter(style => style !== "unspecified").map(style => ({
    label: RESPONSE_STYLE_DESCRIPTIONS[style],
    value: style
  }));
  async function handleLocationCapture() {
    if (!navigator.geolocation) {
      locationStatusSignal.value = "Geolocation is not supported by your browser";
      return;
    }
    try {
      locationStatusSignal.value = "Getting location...";
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      });
      await chatdb.saveLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        accuracy: position.coords.accuracy,
        altitude: position.coords.altitude ?? undefined,
        altitudeAccuracy: position.coords.altitudeAccuracy ?? undefined,
        heading: position.coords.heading ?? undefined,
        speed: position.coords.speed ?? undefined
      });

      // Refresh the last location after saving
      const newLocation = await chatdb.getLatestLocation();
      lastLocationSignal.value = newLocation;
      locationStatusSignal.value = "Location saved successfully!";
    } catch (error) {
      locationStatusSignal.value = "Error capturing location: " + (error as Error).message;
    }
  }
  function formatLocation(location: LocationType) {
    const date = new Date(location.timestamp * 1000);
    return <div className="text-sm text-gray-600 space-y-1" data-sentry-component="formatLocation" data-sentry-source-file="authmenu.tsx">
        <div>
          Latitude: {location.latitude.toFixed(6)}°
          <br />
          Longitude: {location.longitude.toFixed(6)}°
          {location.accuracy && <>
              <br />
              Accuracy: ±{location.accuracy.toFixed(0)}m
            </>}
        </div>
        <div>Recorded: {date.toLocaleString()}</div>
      </div>;
  }
  if (!settingsSignal.value) {
    return <div>Loading...</div>;
  }
  return <div data-sentry-component="SettingsPage" data-sentry-source-file="authmenu.tsx">
      <H1 data-sentry-element="H1" data-sentry-source-file="authmenu.tsx">Settings Page</H1>
      <Label title="Your Assistant's Name" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
        <TextInput signal={assistantNameSignal} placeholder={DEFAULT_ASSISTANT_NAME} data-sentry-element="TextInput" data-sentry-source-file="authmenu.tsx" />
      </Label>
      <Label title="Voice" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
        <Select options={options} signal={voiceSignal} data-sentry-element="Select" data-sentry-source-file="authmenu.tsx" />
      </Label>
      <Label title="Voice Model" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
        <Select options={ttsModelOptions} signal={ttsModelSignal} data-sentry-element="Select" data-sentry-source-file="authmenu.tsx" />
      </Label>
      <Label title="AI Model" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
        <Select options={modelOptions} signal={modelSignal} data-sentry-element="Select" data-sentry-source-file="authmenu.tsx" />
      </Label>
      <Label title="Response Style" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
        <Select options={responseStyleOptions} signal={responseStyleSignal} data-sentry-element="Select" data-sentry-source-file="authmenu.tsx" />
      </Label>
      <FieldSet title="Application & Push" data-sentry-element="FieldSet" data-sentry-source-file="authmenu.tsx">
        <InstallPrompt data-sentry-element="InstallPrompt" data-sentry-source-file="authmenu.tsx" />
        <PushNotificationPrompt data-sentry-element="PushNotificationPrompt" data-sentry-source-file="authmenu.tsx" />
      </FieldSet>
      <FieldSet title="Development Options" data-sentry-element="FieldSet" data-sentry-source-file="authmenu.tsx">
        <Label title="Debug Mode" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
          <Button onClick={() => {
          debugModeSignal.value = !debugModeSignal.value;
        }} data-sentry-element="Button" data-sentry-source-file="authmenu.tsx">
            {debugModeSignal.value ? "ON" : "off"}
          </Button>
        </Label>
        <Label title="Reset onboarding" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
          <Button onClick={() => {
          return chatdb.resetOnboarding();
        }} confirm="Are you sure you want to reset onboarding?" data-sentry-element="Button" data-sentry-source-file="authmenu.tsx">
            Reset onboarding
          </Button>
        </Label>
      </FieldSet>
      <FieldSet title="Location" data-sentry-element="FieldSet" data-sentry-source-file="authmenu.tsx">
        <Label title="Current Location" className="mb-4" data-sentry-element="Label" data-sentry-source-file="authmenu.tsx">
          <div className="space-y-2">
            <Button onClick={handleLocationCapture} Icon={MyLocationIcon} data-sentry-element="Button" data-sentry-source-file="authmenu.tsx">
              Record Current Location
            </Button>
            {locationStatusSignal.value && <div className="text-sm text-gray-600">
                {locationStatusSignal.value}
              </div>}
            {lastLocationSignal.value && <div className="mt-4">
                <div className="font-medium text-sm mb-1">
                  Last Recorded Location:
                  {formatLocation(lastLocationSignal.value)}
                  <PastDateView date={lastLocationSignal.value.timestamp} />
                </div>
              </div>}
          </div>
        </Label>
      </FieldSet>
    </div>;
}