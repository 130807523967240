"use client";

import chatdb from "@/lib/client/chatdb";
import { useSignal, useSignals } from "@preact/signals-react/runtime";
import { useEffect } from "react";
import { isIOS } from "./audio";
import { P } from "./display";
import { Button, TextInput } from "./input";
type BeforeInstallPromptEvent = Event & {
  userChoice: Promise<{
    outcome: "accepted" | "dismissed";
  }>;
  prompt: () => Promise<{
    outcome: "accepted" | "dismissed";
  }>;
};
export function InstallPrompt() {
  useSignals();
  const iOSSignal = useSignal(false);
  const standaloneSignal = useSignal(false);
  // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
  const deferredPromptSignal = useSignal<BeforeInstallPromptEvent | null>(null);
  useEffect(() => {
    iOSSignal.value = isIOS();
    standaloneSignal.value = window.matchMedia("(display-mode: standalone)").matches;
    function handler(e: Event) {
      e.preventDefault();
      deferredPromptSignal.value = e as BeforeInstallPromptEvent;
    }

    // Handle the beforeinstallprompt event for non-iOS devices
    window.addEventListener("beforeinstallprompt", handler);
    return () => {
      window.removeEventListener("beforeinstallprompt", handler);
    };
  }, [iOSSignal, standaloneSignal, deferredPromptSignal]);
  if (standaloneSignal.value) {
    return null;
  }
  async function showInstallPrompt() {
    if (deferredPromptSignal.value) {
      await (deferredPromptSignal.value as any).prompt();
      deferredPromptSignal.value = null;
    }
  }
  return <div data-sentry-component="InstallPrompt" data-sentry-source-file="appsubscriptions.tsx">
      <div className="font-bold">Install App</div>
      {iOSSignal.value ? <P>
          To install this app on your iOS device, tap the share button
          <span role="img" aria-label="share icon">
            {" ⎋ "}
          </span>
          and then "Add to Home Screen"
          <span role="img" aria-label="plus icon">
            {" ➕ "}
          </span>
          .
        </P> : <>
          {deferredPromptSignal.value && <Button onClick={showInstallPrompt}>Install App</Button>}
          {!deferredPromptSignal.value && <P>
              To install this app, use your browser's "Install" or "Add to Home
              Screen" option from the menu.
            </P>}
        </>}
    </div>;
}
export function PushNotificationPrompt() {
  useSignals();
  const isSupportedSignal = useSignal(false);
  const subscriptionSignal = useSignal<PushSubscription | null>(null);
  const messageSignal = useSignal("");
  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      isSupportedSignal.value = true;
      registerServiceWorker();
    }
  }, [isSupportedSignal]);
  if (!isSupportedSignal.value) {
    return <p>Push notifications are not supported in this browser.</p>;
  }
  async function registerServiceWorker() {
    const registration = await navigator.serviceWorker.register("/sw.js", {
      scope: "/",
      updateViaCache: "none"
    });
    const sub = await registration.pushManager.getSubscription();
    subscriptionSignal.value = sub;
  }
  async function subscribeToPush() {
    const registration = await navigator.serviceWorker.ready;
    const sub = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY!)
    });
    subscriptionSignal.value = sub;
    const serializedSub = JSON.parse(JSON.stringify(sub));
    await chatdb.subscribeUser(serializedSub);
  }
  async function unsubscribeFromPush() {
    if (!subscriptionSignal.value) {
      console.error("No subscription to unsubscribe from");
      return;
    }
    const endpoint = subscriptionSignal.value.endpoint;
    await subscriptionSignal.value.unsubscribe();
    subscriptionSignal.value = null;
    await chatdb.unsubscribeUser({
      endpoint
    });
  }
  async function sendTestNotification() {
    if (subscriptionSignal.value) {
      await chatdb.debugSendNotification({
        message: messageSignal.value
      });
      messageSignal.value = "";
    }
  }
  return <div data-sentry-component="PushNotificationPrompt" data-sentry-source-file="appsubscriptions.tsx">
      <div className="font-bold">Push Notifications</div>
      {subscriptionSignal.value ? <>
          <P>You are subscribed to push notifications.</P>
          <Button onClick={unsubscribeFromPush}>Unsubscribe</Button>
          <TextInput placeholder="Enter notification message" signal={messageSignal} />
          <Button onClick={sendTestNotification}>Send Test</Button>
        </> : <>
          <P>You are not subscribed to push notifications.</P>
          <Button onClick={subscribeToPush}>Subscribe</Button>
        </>}
    </div>;
}
function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = "=".repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}