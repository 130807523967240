import type {
  TranscriptionSegment,
  TranscriptionWord,
} from "openai/resources/audio/transcriptions.mjs";
import type {
  ChatCompletion,
  ChatCompletionCreateParamsBase,
} from "openai/resources/chat/completions.mjs";
import type { VoiceType } from "./tts";

export type UserType = {
  userId: string;
  imageUrl?: string;
  username?: string;
  firstName: string;
  lastName: string;
  primaryEmailAddressId: string;
  emailAddresses: { items: EmailItem[] };
  primaryPhoneNumberId: string;
  phoneNumbers: any;
  messagingPhoneNumber?: string;
  settings: UserSettingsType;
  timezone?: string;
  created: number;
};

export type UserSettingsType = {
  voice?: VoiceType;
  responseStyle?: ResponseStyleType;
  modelFamily?: ModelFamilyType;
  ttsModel?: TtsModelType;
  assistantName?: string;
  onboarded?: {
    home?: boolean;
    settings?: boolean;
    homeWithDocument?: boolean;
    capture?: boolean;
  };
  isOnboardDocumentComplete?: boolean;
};

export type OnboardedSettingNameType = keyof NonNullable<
  UserSettingsType["onboarded"]
>;

export const DEFAULT_ASSISTANT_NAME = "Robin";
// Other options...
// Wren, Pippin, Moxie, Lark, Pip, Coco, Scout, Callen, Nova

export type ResponseStyleType =
  | "long"
  | "medium"
  | "short"
  | "concise"
  | "unspecified";
export const RESPONSE_STYLE_DESCRIPTIONS: Record<ResponseStyleType, string> = {
  concise: "Concise (very short)",
  short: "Short",
  medium: "Medium (normal)",
  long: "Long (explicitly verbose)",
  unspecified: "Unspecified",
};
export const RESPONSE_STYLES = Object.keys(
  RESPONSE_STYLE_DESCRIPTIONS
) as ResponseStyleType[];
export const DEFAULT_RESPONSE_STYLE: ResponseStyleType = "medium";

export type ModelFamilyType = "gpt" | "claude" | "gemini";
export const MODEL_FAMILY_DESCRIPTIONS: Record<ModelFamilyType, string> = {
  gpt: "OpenAI ChatGPT",
  claude: "Anthropic Claude",
  gemini: "Google Gemini",
};
export const MODEL_FAMILIES = Object.keys(
  MODEL_FAMILY_DESCRIPTIONS
) as ModelFamilyType[];
export const DEFAULT_MODEL_FAMILY: ModelFamilyType = "claude";

export type ModelType = "regular" | "mini" | "o3-mini";
export const MODELS: Record<ModelFamilyType, Record<ModelType, string>> = {
  gpt: {
    regular: "gpt-4o",
    mini: "gpt-4o-mini",
    "o3-mini": "o3-mini",
  },
  claude: {
    regular: "anthropic/claude-3-7-sonnet-20250219",
    // Note OpenRouter has a different name...
    // regular: "anthropic/claude-3.7-sonnet",
    mini: "anthropic/claude-3-5-haiku-20241022",
    // OpenRouter name:
    // mini: "anthropic/claude-3.5-haiku",
    "o3-mini": "o3-mini",
  },
  gemini: {
    regular: "google/gemini-pro-1.5",
    mini: "google/gemini-2.0-flash-001",
    "o3-mini": "o3-mini",
  },
};

export type TtsModelType = "tts-1" | "gpt-4o-mini-tts";
export const DEFAULT_TTS_MODEL: TtsModelType = "tts-1";
export const TTS_MODELS = ["tts-1", "gpt-4o-mini-tts"] as const;
export const TTS_MODEL_DESCRIPTIONS: Record<TtsModelType, string> = {
  "tts-1": "Standard speech",
  "gpt-4o-mini-tts": "Speech with emotion",
};
export const TTS_MODELS_WITH_INSTRUCTIONS: TtsModelType[] = [
  "gpt-4o-mini-tts",
] as const;

export type UserPersonalityType = {
  name: string;
  description: string;
};

type EmailVerification = {
  nonce: string | null;
  status: "verified" | "unverified";
  message: string | null;
  attempts: number | null;
  expireAt: string | null;
  strategy: string | null;
  externalVerificationRedirectURL: string | null;
};

type EmailItem = {
  id: string;
  linkedTo: { id: string; type: string }[];
  emailAddress: string;
  verification: EmailVerification;
};

export type UserRoleType = "user";

export type LlmLogType = {
  llmLogId: number;
  userId: string;
  groupId?: string;
  messageId?: string;
  documentId?: string;
  conversationId?: string;
  createdTimestamp: number;
  responseTime: number;
  provider: string;
  model: string;
  tokensIn: number;
  tokensOut: number;
  cacheCreationInputTokens?: number;
  cacheReadInputTokens?: number;
  inputCachedTokens?: number;
  prompt: ChatPrompt;
  images?: string[];
  response: ChatCompletion;
};

export interface ChatPrompt
  extends Omit<
    ChatCompletionCreateParamsBase,
    | "audio"
    | "function_call"
    | "functions"
    | "modalities"
    | "store"
    | "stream"
    | "stream_options"
    | "model"
  > {
  model?: ModelType;
}

export type UserContextType = {
  userId: string;
  groupId?: string;
  conversationId?: string;
  messageId?: string;
  documentId?: string;
  toolCallId?: string;
};

export type GroupType = {
  groupId: string;
  slug: string;
  name: string;
  description?: string;
  appearance?: any;
  createdTimestamp: number;
};

export type AudioType = {
  audioId: string;
  userId: string;
  recordingSessionId: string;
  groupId: string;
  documentId?: string;
  name: string;
  size: number;
  fileType: string;
  userUrl: string;
  audioLength: number;
  transcriptText: string;
  transcriptStructure: {
    words: TranscriptionWord[];
    segments: TranscriptionSegment[];
  };
  audioCreatedTimestamp: number;
  processed: boolean;
  createdTimestamp: number;
};

export type ImageType = {
  imageId: string;
  userId: string;
  recordingSessionId: string;
  groupId: string;
  documentId?: string;
  name: string;
  size: number;
  fileType: string;
  userUrl: string;
  description: string;
  imageCreatedTimestamp: number;
  createdTimestamp: number;
};

export type RecordingSessionSummaryType = {
  recordingSessionId: string;
  oldestRecording: number;
  newestRecording: number;
  totalRecordings: number;
  totalTimeRecorded: number;
  totalAudioSize: number;
  totalWords: number;
  totalImages: number;
  totalImageSize: number;
  totalItems: number;
};

export type GroupInviteType = {
  groupInviteId: number;
  groupId: string;
  groupName: string;
  inviteCode: string;
  expiration: number;
  isExpired: boolean;
  valid: boolean;
  invitedByUserId: string;
  inviteUser: UserType;
  usedByUserId: string;
  createdTimestamp: number;
};

// This is a generator that yields T, but can also yield a promise that resolves to T
// The promises are sent whenever they complete, without blocking other yields
export type AsyncGeneratorOOO<
  T,
  TReturn = any,
  TNext = unknown,
> = AsyncGenerator<T | { promise: Promise<T> }, TReturn, TNext>;

// On the client side AsyncGeneratorOOO is normalized to AsyncGenerator, and this adapts those types:
export type NormalizeAsyncGeneratorFunction<T> = T extends (
  ...args: infer A
) => AsyncGeneratorOOO<infer U, infer TReturn, infer TNext>
  ? (...args: A) => AsyncGenerator<U, TReturn, TNext> // Transform only return type
  : T;

// Recursively normalize all function return types in objects
export type NormalizeAsyncGeneratorFunctions<T> = {
  [K in keyof T]: NormalizeAsyncGeneratorFunction<T[K]>;
};

export function isAsyncGenerator(obj: any): boolean {
  return (
    obj &&
    typeof obj.next === "function" &&
    typeof obj.throw === "function" &&
    obj[Symbol.asyncIterator] === obj
  );
}

export type DocumentDescriptionType = {
  documentId: string;
  ownerUserId?: string;
  documentPrivate: boolean;
  relatedDocumentIds?: string[];
  groupId: string;
  type: string;
  flavor: DocumentFlavorType;
  title: string;
  // Short description used when selecting documents:
  purpose: string;
  created: number;
};

export type DocumentFlavorType = any;

export type DatabaseJsonDocumentType<
  T extends Record<string, any> = Record<string, any>,
> = {
  documentId: string;
  groupId: string;
  type: string;
  ob: T;
  created: number;
  modified: number;
};

export type LocationType = {
  locationId: string;
  userId: string;
  // Coordinates in WGS 84 format
  latitude: number;
  longitude: number;
  // Optional GPS metadata
  accuracy?: number;
  altitude?: number;
  altitudeAccuracy?: number;
  heading?: number;
  speed?: number;
  timestamp: number;
};

export type LocationInputType = Omit<LocationType, "locationId" | "timestamp">;
