"use client";

import { persistentSignal } from "@/lib/signals";
import { ArrowLeftIcon, EllipsisVerticalIcon } from "@heroicons/react/16/solid";
import Link from "next/link";
import { ElementType, ReactNode, useEffect, useLayoutEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import AuthMenu from "./authmenu";
import { OnboardButton } from "./onboarding";
import { A } from "./input";
export const debugModeSignal = persistentSignal("debugMode", false);
export function AppPage({
  title,
  rightHeader,
  wide,
  fullHeight,
  noFooter,
  children
}: {
  title: ReactNode;
  rightHeader?: ReactNode;
  wide?: boolean;
  fullHeight?: boolean;
  noFooter?: boolean;
  children: ReactNode;
}) {
  const [year, setYear] = useState(2025);
  useEffect(() => {
    if (typeof window !== "undefined") {
      setYear(new Date().getFullYear());
    }
  }, []);
  return <div className={twMerge("min-h-dvh", fullHeight ? "h-dvh" : "")} data-sentry-component="AppPage" data-sentry-source-file="apppage.tsx">
      <header className="w-full fixed flex items-center justify-between px-2 sm:px-8 py-1 sm:py-2 border-b h-8 sm:h-12 bg-gray-100 z-10 shadow-md">
        <div className="flex items-center justify-between space-x-4">
          <a href="/">
            <img src="/icons/icon.png" alt="Memory Atlas Icon" className="w-4 h-4 sm:w-10 sm:h-10" />
          </a>
          <h1 className="text-sm sm:text-2xl font-bold">{title}</h1>
        </div>
        <div>
          {rightHeader}
          <AuthMenu data-sentry-element="AuthMenu" data-sentry-source-file="apppage.tsx" />
        </div>
      </header>

      <main className={twMerge("pt-6 md:pt-12 px-1 md:px-8 space-y-8", wide ? "" : "max-w-4xl mx-auto", fullHeight ? "h-full" : "")}>
        {children}
      </main>

      {!noFooter && <footer className="w-full py-4 text-center border-t text-gray-500 text-sm">
          <A href="/tos#tos">Terms Of Service</A> |{" "}
          <A href="/tos#privacy">Privacy Policy</A> | &copy; {year} Ian Bicking.
          All rights reserved.
        </footer>}
    </div>;
}
export function MobilePage({
  children
}: {
  children: ReactNode;
}) {
  useLayoutEffect(() => {
    const setVh = () => {
      const vh = window.visualViewport ? window.visualViewport.height : window.innerHeight;
      document.documentElement.style.setProperty("--vh", `${vh * 0.01}px`);
    };
    setVh();
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", setVh);
    } else {
      window.addEventListener("resize", setVh);
    }
    return () => {
      if (window.visualViewport) {
        window.visualViewport.removeEventListener("resize", setVh);
      } else {
        window.removeEventListener("resize", setVh);
      }
    };
  }, []);
  return <div className="h-screen-vh flex flex-col max-w-screen-lg mx-auto" data-sentry-component="MobilePage" data-sentry-source-file="apppage.tsx">
      {children}
    </div>;
}
export function ScreenLayout({
  title,
  backLink,
  children
}: {
  title: ReactNode;
  backLink: string;
  children: ReactNode;
}) {
  return <div className="flex flex-col h-full bg-gray-100" data-sentry-component="ScreenLayout" data-sentry-source-file="apppage.tsx">
      {/* Header */}
      <div className="flex items-center justify-between p-4 bg-white shadow-md">
        <div className="flex items-center">
          <Link href={backLink} data-sentry-element="Link" data-sentry-source-file="apppage.tsx">
            <ArrowLeftIcon className="h-6 w-6 text-gray-600" data-sentry-element="ArrowLeftIcon" data-sentry-source-file="apppage.tsx" />
          </Link>
          <h1 className="text-lg font-semibold ml-4">{title}</h1>
        </div>
        <OnboardButton data-sentry-element="OnboardButton" data-sentry-source-file="apppage.tsx" />
      </div>
      <div className="flex-grow overflow-auto p-4 space-y-6">{children}</div>
    </div>;
}
export function SettingsSection({
  title,
  children
}: {
  title: ReactNode;
  children?: ReactNode;
}) {
  return <div className="bg-white rounded-lg shadow-md p-4" data-sentry-component="SettingsSection" data-sentry-source-file="apppage.tsx">
      <h2 className="text-gray-800 font-semibold text-base">{title}</h2>
      <div className="space-y-2 mt-2">{children}</div>
    </div>;
}
export function SettingsLink({
  id,
  href,
  isActive,
  Icon,
  children,
  onEllipsis,
  className
}: {
  id?: string;
  href: string;
  isActive?: boolean;
  Icon?: ElementType<{
    className?: string | undefined;
  }>;
  children: ReactNode;
  onEllipsis?: () => void;
  className?: string;
}) {
  return <div className={twMerge("bg-gray-100 rounded-lg flex items-center justify-between", isActive ? "bg-blue-100" : "", className)} data-sentry-component="SettingsLink" data-sentry-source-file="apppage.tsx">
      <Link id={id} href={href} className="block py-2 px-4 flex-grow" data-sentry-element="Link" data-sentry-source-file="apppage.tsx">
        {children}
        {Icon && <Icon className="h-4 w-4 ml-2 text-blue-500 inline-block" />}
      </Link>
      {onEllipsis && <button onClick={onEllipsis} className="border-l h-full w-12 flex-shrink-0">
          <EllipsisVerticalIcon className="h-4 w-4 inline-block" />
        </button>}
    </div>;
}
export function SettingsLinkSection({
  header,
  onHeaderClick,
  children,
  className
}: {
  header: ReactNode;
  onHeaderClick?: () => void;
  children: ReactNode;
  className?: string;
}) {
  return <div className={twMerge("bg-gray-100 rounded-lg", className)} data-sentry-component="SettingsLinkSection" data-sentry-source-file="apppage.tsx">
      <div className="flex items-center justify-between border-b">
        <div onClick={onHeaderClick} className="py-2 px-4 flex-grow">
          {header}
        </div>
        <button className="border-l h-full w-12 flex-shrink-0" onClick={onHeaderClick}>
          <EllipsisVerticalIcon className="h-4 w-4 inline-block" data-sentry-element="EllipsisVerticalIcon" data-sentry-source-file="apppage.tsx" />
        </button>
      </div>
      <div className="p-4">{children}</div>
    </div>;
}