import { createHmac, randomUUID } from "crypto";
import { gunzipSync, gzipSync } from "zlib";
import { uploadBlob } from "./s3";
import { DEFAULT_TTS_MODEL, type TtsModelType } from "./types";

type TTSParameters = {
  input: string;
  instructions?: string;
  voice?: string;
  responseFormat?: string;
  model?: TtsModelType;
};

export type VoiceType =
  | "alloy"
  | "ash"
  | "coral"
  | "echo"
  | "fable"
  | "onyx"
  | "nova"
  | "sage"
  | "shimmer";

export const VOICE_DESCRIPTIONS: Record<VoiceType, string> = {
  alloy: "female, low",
  ash: "male, low, Cowboy?",
  coral: "female, medium, True Crime Podcaster?",
  echo: "neutral, medium",
  fable: "neutral, medium, British?",
  onyx: "male, low",
  nova: "female, high",
  sage: "female, high, the True Crime Podcaster's cohost",
  shimmer: "female, medium",
};

export const VOICE_GENDERS: Record<VoiceType, "male" | "female" | "neutral"> = {
  alloy: "female",
  ash: "male",
  coral: "female",
  echo: "neutral",
  fable: "neutral",
  onyx: "male",
  nova: "female",
  sage: "female",
  shimmer: "female",
};

export const VOICES = Object.keys(VOICE_DESCRIPTIONS) as VoiceType[];

export const DEFAULT_VOICE: VoiceType = "alloy";

export function voiceSampleUrl(voice: VoiceType) {
  return `/voice-samples/${voice}.mp3`;
}

export async function tts({
  input,
  voice = DEFAULT_VOICE,
  responseFormat = "mp3",
}: TTSParameters) {
  if (!input) {
    throw new Error("No input provided for tts");
  }
  const response = await fetch("https://api.openai.com/v1/audio/speech", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${process.env.OPENAI_API_KEY}`,
    },
    body: JSON.stringify({
      model: "tts-1",
      input,
      voice,
      response_format: responseFormat,
    }),
  });
  if (!response.ok) {
    const json = await response.json();
    console.error(
      `TTS error ${response.status} ${response.statusText}:`,
      json,
      { input, voice }
    );
    throw new Error(
      `Failed to fetch audio: ${response.status} ${response.statusText}`
    );
  }
  return await response.blob();
}

export async function ttsUrl(params: TTSParameters) {
  const blob = await tts(params);
  const filename = `tts-audio/${randomUUID()}.mp3`;

  return uploadBlob(blob, filename, { expiresIn: 3600 });
  // Previously:
  // return blobToDataURL(await tts(params));
}

export async function blobToDataURL(blob: Blob): Promise<string> {
  if (typeof FileReader === "undefined") {
    const buffer = Buffer.from(await blob.arrayBuffer());
    const base64 = buffer.toString("base64");
    const mimeType = blob.type || "application/octet-stream";
    return `data:${mimeType};base64,${base64}`;
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
}

export function ttsStreamUrl({
  input,
  instructions,
  voice = DEFAULT_VOICE,
  model = DEFAULT_TTS_MODEL,
}: TTSParameters) {
  if (!input) {
    throw new Error("No input provided for tts");
  }

  // Compress and sign the input data object
  const data = JSON.stringify({ speech: input, instructions });
  const encodedAndSigned = compressAndSign(data);

  // Construct the URL with query parameters
  const params = new URLSearchParams({
    data: encodedAndSigned,
    model,
    voice,
  });

  return `/api/tts?${params.toString()}`;
}

function compressAndSign(input: string): string {
  const compressed = gzipSync(Buffer.from(input));
  const encoded = compressed.toString("base64");
  const hmac = createHmac("sha256", process.env.CRON_SECRET || "");
  hmac.update(encoded);
  const signature = hmac.digest("hex");
  return `${encoded}:${signature}`;
}

export function decodeAndVerify(encodedAndSigned: string): string {
  const [encoded, signature] = encodedAndSigned.split(":");
  const hmac = createHmac("sha256", process.env.CRON_SECRET || "");
  hmac.update(encoded);
  const computedSignature = hmac.digest("hex");
  if (computedSignature !== signature) {
    throw new Error("Invalid signature");
  }
  return gunzipSync(Buffer.from(encoded, "base64")).toString();
}
