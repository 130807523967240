import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
  DeleteObjectCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

const s3 = new S3Client({
  region: "auto",
  endpoint: process.env.R2_ENDPOINT!,
  // endpoint: `https://${ACCOUNT_ID}.r2.cloudflarestorage.com`,
  credentials: {
    accessKeyId: process.env.R2_ACCESS_KEY_ID!,
    secretAccessKey: process.env.R2_ACCESS_SECRET_ID!,
  },
});

export async function createPutUrl(filename: string) {
  return getSignedUrl(
    s3,
    new PutObjectCommand({ Bucket: process.env.R2_BUCKET!, Key: filename }),
    { expiresIn: 3600 }
  );
}

export async function createGetUrl(
  filename: string,
  { expiresIn = 3600 } = {}
) {
  return getSignedUrl(
    s3,
    new GetObjectCommand({ Bucket: process.env.R2_BUCKET!, Key: filename }),
    { expiresIn }
  );
}

export async function deleteObject(filename: string) {
  return s3.send(
    new DeleteObjectCommand({ Bucket: process.env.R2_BUCKET!, Key: filename })
  );
}

export async function uploadBlob(
  blob: Blob,
  filename: string,
  { expiresIn = 3600 } = {}
) {
  // Get a signed URL for uploading
  const putUrl = await createPutUrl(filename);

  // Upload the blob using fetch with Content-Type header
  const response = await fetch(putUrl, {
    method: "PUT",
    body: blob,
    headers: {
      "Content-Type": blob.type || "application/octet-stream",
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to upload blob: ${response.statusText}`);
  }

  // Return a signed URL to access the uploaded file
  return createGetUrl(filename, { expiresIn });
}
