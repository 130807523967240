import markdown from "markdown-it";
import { twMerge } from "tailwind-merge";
import { hashParamsSignal } from "./hashparams";
export function Markdown({
  children,
  text,
  lightLinks,
  className
}: {
  children?: string;
  text?: string;
  lightLinks?: boolean;
  className?: string;
}) {
  // FIXME: Not sure what I should do here... hashParamsSignal kind of requires this. But it breaks server rendering.
  // useSignals();
  if (!children && !text) {
    return null;
  }
  const html = md.render(children ?? text ?? "");
  return <div className={twMerge("unreset", lightLinks ? "light-link" : "", className)} dangerouslySetInnerHTML={{
    __html: html
  }} data-sentry-component="Markdown" data-sentry-source-file="markdown.tsx" />;
}
const md = markdown({
  breaks: true,
  linkify: true
});

// Remember the old renderer if overridden, or proxy to the default renderer.
const defaultLinkRender = md.renderer.rules.link_open;
md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  const origin = typeof window === "undefined" ? "https://www.mymemoryatlas.com" : window.location.origin;
  if (tokens[idx].attrGet("href")) {
    const url = new URL(tokens[idx].attrGet("href")!, origin);
    if (url.origin !== origin) {
      // Add a new `target` attribute, or replace the value of the existing one.
      tokens[idx].attrSet("target", "_blank");
      tokens[idx].attrSet("rel", "noopener");
    } else if (url.hash) {
      const currentParams = new URLSearchParams(typeof window === "undefined" ? "" : hashParamsSignal.value);
      const newParams = new URLSearchParams(url.hash.slice(1));
      for (const [key, value] of newParams.entries()) {
        currentParams.set(key, value);
      }

      // Special casing todos...
      if (currentParams.get("todo")) {
        currentParams.set("tab", "doc");
      }
      const mergedHash = currentParams.toString();
      const newHref = mergedHash ? `#${mergedHash}` : "";
      tokens[idx].attrSet("href", newHref);
    }
  }

  // Pass the token to the default renderer
  if (defaultLinkRender) {
    return defaultLinkRender(tokens, idx, options, env, self);
  } else {
    return self.renderToken(tokens, idx, options);
  }
};